import { useCallback } from "react";

import { RESOURCES_SUBMENUS, RESOURCES_URL } from "Constants/Menu.Constant";
import { classnames } from "Utils/Classnames.Util";

import { Typography } from "../../../Typography";

import classNames from "classnames";
import MenuItem from "./MenuItem";

const ResourceCollapse = ({
  menu,
  currentPath,
  index,
  isExpanding,
  onToggle,
}: {
  menu: { id: number; label: string; url: string };
  currentPath: string;
  index: number;
  isExpanding: boolean;
  onToggle: (index: number) => void;
}) => {
  const checkActiveMenu = useCallback(
    (url: string) => {
      return currentPath === url;
    },
    [currentPath],
  );

  return (
    <div
      tabIndex={index}
      className={classnames(
        "collapse relative flex flex-col text-clever-black",
        {
          "collapse-close": !isExpanding,
        },
      )}
    >
      <li
        suppressHydrationWarning={true}
        onClick={(event) => {
          event.stopPropagation();
          onToggle(index);
        }}
        className={classnames("collapse-title min-h-0 p-0", {
          "text-clever-link font-medium": RESOURCES_URL.includes(currentPath),
        })}
      >
        <Typography.Heading3 className="flex justify-between">
          {menu.label}
          <img
            src="Assets/Svg/arrow-right.svg"
            alt="arrow"
            className={classNames({ "rotate-90": isExpanding })}
          />
        </Typography.Heading3>
      </li>

      <div className="collapse-content pb-0 sm:ml-3">
        <ul className="menu my-2">
          <Typography.Description className="menu-title font-semibold text-clever-description">
            {RESOURCES_SUBMENUS.title}
          </Typography.Description>

          {RESOURCES_SUBMENUS.submenu.map((submenu) => {
            return (
              <MenuItem
                key={submenu.id}
                menu={submenu}
                active={checkActiveMenu(submenu.url)}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ResourceCollapse;
