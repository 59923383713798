import { useState } from "react";

import { classnames } from "Utils/Classnames.Util";
import { BUSINESS_SUBMENUS, BUSINESS_URL } from "Constants/Menu.Constant";

import { Typography } from "../../../Typography";

import MenuItem from "./MenuItem";
import classNames from "classnames";

const BussinessCollapse = ({
  menu,
  currentPath,
  index,
  isExpanding,
  onToggle,
}: {
  menu: { id: number; label: string; url: string };
  currentPath: string;
  index: number;
  isExpanding: boolean;
  onToggle: (index: number) => void;
}) => {
  return (
    <div tabIndex={index} className={classnames("flex flex-col")}>
      <li
        suppressHydrationWarning={true}
        onClick={(event) => {
          event.stopPropagation();
          onToggle(index);
        }}
        className={classnames("min-h-0 p-0", {
          "text-clever-link font-medium": BUSINESS_URL.includes(currentPath),
        })}
      >
        <Typography.Heading3 className="flex justify-between">
          {menu.label}
          <img
            src="Assets/Svg/arrow-right.svg"
            alt="arrow"
            className={classNames({ "rotate-90": isExpanding && index === 1 })}
          />
        </Typography.Heading3>
      </li>

      {isExpanding && index === 1 && (
        <div className="ml-3">
          <ul className="my-2">
            <Typography.Description className="menu-title font-semibold text-clever-description">
              {BUSINESS_SUBMENUS.INDUSTRY.title}
            </Typography.Description>
            {BUSINESS_SUBMENUS.INDUSTRY.submenu.map((submenu) => {
              return (
                <MenuItem
                  key={submenu.id}
                  menu={submenu}
                  active={submenu.url === currentPath}
                />
              );
            })}

            <Typography.Description className="menu-title font-semibold text-clever-description">
              {BUSINESS_SUBMENUS.SOLUTION.title}
            </Typography.Description>

            {BUSINESS_SUBMENUS.SOLUTION.submenu.map((submenu) => {
              return (
                <MenuItem
                  key={submenu.id}
                  menu={submenu}
                  active={submenu.url === currentPath}
                  target={submenu.target}
                />
              );
            })}

            <Typography.Description className="menu-title font-semibold text-clever-description">
              {BUSINESS_SUBMENUS.LINKS.title}
            </Typography.Description>

            {BUSINESS_SUBMENUS.LINKS.submenu.map((submenu) => {
              return (
                <MenuItem
                  key={submenu.id}
                  menu={submenu}
                  active={submenu.url === currentPath}
                  target={submenu.target}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BussinessCollapse;
