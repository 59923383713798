import { memo, useEffect, useState } from "react";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { NextLink } from "Components/Lib/Next";
import { Button } from "Components/Own";
import { NormalImage } from "Components/Lib";
import { PLATFORM } from "Constants/Platform.Constant";
import { APP_DOWNLOAD_URL } from "Constants/URL.Constant";
import { classnames } from "Utils/Classnames.Util";
import { checkOS } from "Utils/Common.Util";
import { localesText } from "Utils/LocalesText.Util";

import androidIcon from "Assets/Images/Icons/android.png";
import iosIcon from "Assets/Images/Icons/ios.png";

import { Typography } from "../Typography";

const AppDownLoadButton = ({
  theme = "light",
  className,
  showSeperate = false,
  seperateButtonClassName,
  important = true,
}: {
  theme?: "dark" | "light";
  seperateButtonClassName?: string | undefined;
  className?: string | undefined;
  showSeperate?: boolean;
  important?: boolean;
}) => {
  const [currentOs, setCurrentOS] = useState<string | undefined>(undefined);

  useEffect(() => {
    const os = checkOS();
    setCurrentOS(os);
  }, []);

  const androidDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  const iosDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  if (!currentOs) return null;

  if (currentOs === PLATFORM.IOS) {
    return <IOSButton className={className} theme={theme} />;
  }

  if (currentOs === PLATFORM.ANDROID) {
    return <AndroidButton className={className} theme={theme} />;
  }

  if (showSeperate) {
    return (
      <div
        className={classnames(className, {
          "flex flex-wrap items-center justify-center gap-4": important,
        })}
      >
        <AndroidButton className={seperateButtonClassName} theme={theme} />
        <IOSButton className={seperateButtonClassName} theme={theme} />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        "min-h-12 flex h-12 items-center justify-evenly rounded-full",
        {
          "border-2 border-white": theme === "light",
          "bg-black": theme === "dark",
        },
        className,
      )}
    >
      <NextLink
        href={APP_DOWNLOAD_URL.ANDROID}
        target="_blank"
        onClick={androidDownload}
      >
        <NormalImage
          src={androidIcon.src}
          alt="android"
          width={21}
          height={12}
        />
      </NextLink>

      <div className="mx-4 h-full w-[2px] bg-white opacity-10" />
      <NextLink
        href={APP_DOWNLOAD_URL.IOS}
        target="_blank"
        onClick={iosDownload}
      >
        <NormalImage src={iosIcon.src} alt="ios" width={16} height={20} />
      </NextLink>
    </div>
  );
};

const IOSButton = ({
  theme,
  className,
}: {
  className?: string | undefined;
  theme?: "dark" | "light";
}) => {
  const iosDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  return (
    <NextLink href={APP_DOWNLOAD_URL.IOS} target="_blank" onClick={iosDownload}>
      <Button
        className={classnames(
          "flex w-[166px] items-center justify-center gap-2 font-light",
          {
            "border-2 border-white": theme === "light",
            "bg-black": theme === "dark",
          },
          className,
        )}
        shape="circle"
      >
        <NormalImage src={iosIcon.src} alt="ios" width={16} height={20} />
        <Typography.Content shouldScale={false} className="text-white">
          {localesText("common.app_store")}
        </Typography.Content>
      </Button>
    </NextLink>
  );
};

const AndroidButton = ({
  theme,
  className,
}: {
  className?: string | undefined;
  theme?: "dark" | "light";
}) => {
  const androidDownload = () => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.androidDownloadClicked,
    });
  };

  return (
    <NextLink
      href={APP_DOWNLOAD_URL.ANDROID}
      target="_blank"
      onClick={androidDownload}
    >
      <Button
        className={classnames(
          "flex w-[166px] items-center justify-center gap-2 font-light",
          {
            "border-2 border-white": theme === "light",
            "bg-black": theme === "dark",
          },
          className,
        )}
        shape="circle"
      >
        <NormalImage
          src={androidIcon.src}
          alt="android"
          width={21}
          height={12}
        />

        <Typography.Content shouldScale={false} className="text-white">
          {localesText("common.google_play")}
        </Typography.Content>
      </Button>
    </NextLink>
  );
};

export default memo(AppDownLoadButton);
